<template>
    <div>
        <template v-if="topic.hasEditorAccess && TokenService.getPermissions('manage_topics')">
            <div v-for="(editor, index) in topic.editors_requests" :key="index" class="mb-1">
                <template v-if="editor.status == 'pending'">
                    {{ editor.user.name }}
                </template>
                <div class="d-flex gap-1" v-if="editor.status == 'pending'">
                    <button
                        class="btn btn-sm btn-primary"
                        @click="saveBtn(editor, 'approved')">
                        Approved
                    </button>
                    <button 
                        class="btn btn-sm btn-danger"
                        v-b-modal="'content-editor-request-modal-' + editor._id">
                        Declined
                    </button>
                </div>
        
                <b-modal :id="'content-editor-request-modal-' + editor._id" hide-footer size="md" centered title="Note" @hidden="resetModal">
                    <div class="modal_content">
                        <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{ errorMsg }}</b-alert>
        
                        <b-form-textarea class="mb-1" v-model="record.note"></b-form-textarea>
        
                        <div class="modal-footer px-0">
                            <b-button @click="saveBtn(editor, 'declined')" :disabled="loading" variant="danger">
                                <b-spinner v-if="loading" small />
                                Decline
                            </b-button>
                        </div>
                    </div>
                </b-modal>

            </div>
        </template>
        <template v-else>
            <div v-if="!topic.content_editor_request">
                <button
                    class="btn btn-sm btn-primary"
                    @click="editRequestBtn()">
                    Request edit access
                </button>
            </div>
            <div v-else>
                <div v-if="topic.content_editor_request.status == 'pending'">
                    <button
                        disabled
                        class="btn btn-sm btn-primary">
                        Request edit access {{ topic.content_editor_request.status }}
                    </button>
                </div>
                <button
                    v-if="!topic.userHasInTopic && topic.content_editor_request.status != 'pending'"
                    class="btn btn-sm btn-primary"
                    @click="editRequestBtn()">
                    Request edit access
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../../Helpers';
import TokenService from '../../../TokenService';
export default {
    components: {
        BButton,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BAlert,
        BSpinner,
        BFormCheckbox,
        ToastificationContent,
        vSelect,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {},
            TokenService,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        async saveBtn(editor, statusData) {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.record.status = statusData;
            this.loading = true;
            try {
                const resp = await axios.put(`${process.env.VUE_APP_API_URL}/content-writer-request/${editor._id}/status`,
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitStatusUpdated');
                this.$bvModal.hide(`content-editor-request-modal-${editor._id}`);
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        async editRequestBtn() {
            this.loading = true;
            try {
                const resp = await axios.post(process.env.VUE_APP_API_URL + "/content-writer-request/"+this.topic._id,
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitStatusUpdated');
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.note && this.record.status == 'declined') {
                this.errorMsg = "Note field is required."
                return;
            }
            return "ok";
        },
    },
    props: [
        "topic",
    ],
};
</script>
