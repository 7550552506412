<template>
    <b-modal id="show-topics-board" v-model="showTopicBoardModal" hide-footer size="lg" @hidden="onModalHidden">
        <div class="modal_content" style="min-height: 500px" v-if="topic">
            <b-row>
                <b-col md="8">
                    <div class="topic-board-content">
                        <div class="fs-105">
                            {{
                                topic.categoryName +
                                " (" +
                                this.grade.className +
                                ")"
                            }}
                        </div>

                        <TopicBoardAttachments
                            :topic_id="props_selectedTopic._id"
                            :topic_attachments="topicAttachments"
                            @emitTopicBoardAttachmentAdded="getTopicData"
                        ></TopicBoardAttachments>

                        <!-- <ckeditor v-model="comment"></ckeditor> -->
                        <!-- useCustomImageHandler
                        @image-added="ImageRendererItems" -->
                    
                        <!-- <vue-editor v-model="comment"></vue-editor> -->
                        <TopicBoardComment
                            v-if="TokenService.getPermissions('manage_topics_review_board')"
                            class="sticky-comments-boxx"
                            :topic_id="props_selectedTopic._id"
                            @emitTopicBoardCommentAdded="getTopicData"
                        ></TopicBoardComment>
                        
                        <br />
                        <div>Activity</div>
                        <br />
                        <div v-for="history in topicHistory" :key="history._id" class="d-flex gap-1 mb-2">
                            <b-avatar class="mr-1" size="30" :src="history.userAvatar" />
                            <div>
                                <div>
                                    <span class="mr-2">
                                        {{ history.userName + " " + history.action }}
                                    </span>
                                    <span>
                                        {{ formatDate(history.created_at) }}
                                    </span>
                                </div>
                                <div>
                                    <div v-if="history.comment != ''" class="topic-history-comment">
                                        <span v-html="history.comment"></span>
                                    </div>
                                    <div v-else>
                                        <div v-if="(history.fromUsers && history.fromUsers.length > 0) || history.toUsers && history.toUsers.length > 0">
                                            <span v-if="history.fromUsers.length > 0">
                                                <span v-for="(u, uIndex) in history.fromUsers" :key="uIndex">
                                                    <span v-if="u.name && u.name != true && u.name != false">{{ u.name }}</span><template v-if="uIndex < history.fromUsers.length - 1">, </template>
                                                </span>
                                            </span>
                                            <span v-else>None </span>
                                            <feather-icon icon="ArrowRightIcon" size="16" class="align-middle text-body" />
                                            <span v-if="history.toUsers.length > 0">
                                                <span v-for="(u, uIndex) in history.toUsers" :key="uIndex">
                                                    <span v-if="u.name && u.name != true && u.name != false">{{ u.name }}</span><template v-if="uIndex < history.toUsers.length - 1">, </template>
                                                </span>
                                            </span>
                                            <span v-else>None </span>
                                        </div>
                                        <div v-else>
                                            <span v-if="history.from && history.from.name && history.from.name != true && history.from.name != false">{{ history.from.name }} </span>
                                            <span v-else>None </span>
                                            <feather-icon icon="ArrowRightIcon" size="16" class="align-middle text-body" />
                                            <span v-if="history.to && history.to.name && history.to.name != true && history.to.name != false">{{ history.to.name }} </span>
                                            <span v-else>None </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="topic-attachments show-4 mt-1 mb-0" v-if="history.topic_board_attachments && history.topic_board_attachments.length > 0">
                                    <div v-for="item in history.topic_board_attachments" :key="item._id" class="attachment"
                                        v-lazy-container="{
                                            selector: 'img',
                                            error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                            loading: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                        }"
                                        >
                                        <img :src="item.url" :data-src="item.url" alt="" @click="popImage(item)" />
                                        <div class="detail" v-if="item.attachment">
                                            <div class="txt">{{ Helpers.shortenFilename(item.attachment.image_name, 12) }}</div>
                                            <div>{{ Helpers.formatDateTime(item.attachment.created_at) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col md="4">
                    <div class="w-100">
                        <label for="class">Status</label>
                        <!-- :disabled="!TokenService.getPermissions('manage_topics_review_board') || (!topic.hasReviewerAccess && !topic.hasEditorAccess)" -->
                        <v-select 
                                class="w-100"
                                v-model="topicStatus"
                                placeholder="Select Status"
                                :reduce="(statuses) => statuses._id"
                                :options="statuses"
                                label="name"
                                @input="changeTopicStatus()">
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>
                        </v-select>
                    </div>
                    <div class="w-100 mt-2">
                        <label for="class">Assignee</label>
                        <!-- :disabled="!TokenService.getPermissions('manage_topics_review_board') || (!topic.hasReviewerAccess && !topic.hasEditorAccess)" -->
                        <!-- <v-select 
                                class="w-100"
                                v-model="assigneeUser"
                                placeholder="Select Assignee"
                                :reduce="(users) => users._id"
                                :options="users"
                                label="name"
                                @input="changeTopicReviewer()">
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>
                        </v-select> -->
                        <v-select 
                                class="w-100"
                                v-model="assignees"
                                placeholder="Select Assignee"
                                :reduce="(users) => users._id"
                                :options="users"
                                label="name"
                                multiple
                                @input="changeTopicReviewer()">
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>
                        </v-select>
                    </div>
                    <div class="w-100 mt-2">
                        <label for="class">Auditor</label>
                        <v-select 
                                :disabled="!TokenService.getPermissions('manage_topics_review_board') || !topic.hasEditorAccess"
                                class="w-100"
                                v-model="reporterUser"
                                placeholder="Select Auditor"
                                :reduce="(users) => users._id"
                                :options="users"
                                label="name"
                                @input="changeTopicReporter()">
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>
                        </v-select>
                    </div>
                    <!-- <div class="w-100 mt-2">
                        <label for="class">Editors</label>
                        <v-select 
                            :disabled="!TokenService.getPermissions('manage_topics_review_board') || !topic.hasEditorAccess"
                            class="w-100"
                            v-model="writerUser"
                            placeholder="Select Writer"
                            :reduce="(users) => users._id"
                            :options="users"
                            label="name"
                            multiple
                            @input="changeTopicEditor()">
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>
                        </v-select>
                    </div> -->
                    <div class="w-100 mt-2">
                        <label for="class">Editors</label>
                        <v-select 
                            :disabled="!TokenService.getPermissions('manage_topics_review_board') || !topic.hasEditorAccess"
                            class="w-100"
                            v-model="editors"
                            placeholder="Select Editor"
                            :reduce="(users) => users._id"
                            :options="users"
                            label="name"
                            multiple
                            @input="changeTopicEditor()">
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>
                        </v-select>
                    </div>
                    <!-- <div class="w-100 mt-1">
                        <template v-if="TokenService.getPermissions('manage_content_writer_request')">
                            <ContentWriterRequestModal
                                v-if="topic.content_writer_request"
                                :contentWriterRequest="topic.content_writer_request"
                                @emitStatusUpdated="contentWriterRequestModalUpdated"
                            ></ContentWriterRequestModal>
                        </template>
                        <template v-else>
                            <template v-if="topic.content_writer_request">
                                Content preview status:
                                {{ topic.content_writer_request.status }}
                            </template>
                        </template>
                    </div> -->
                    <div class="w-100 mt-1">
                        <ContentWriterRequestModal
                            :topic="topic"
                            @emitStatusUpdated="contentWriterRequestModalUpdated"
                        ></ContentWriterRequestModal>
                        <!-- <template v-if="topic.hasEditorAccess">
                            <ContentWriterRequestModal
                                v-if="topic.content_writer_request"
                                :contentWriterRequest="topic.content_writer_request"
                                @emitStatusUpdated="contentWriterRequestModalUpdated"
                            ></ContentWriterRequestModal>
                        </template>
                        <template v-else>
                            <template v-if="topic.content_writer_request">
                                Content preview status:
                                {{ topic.content_writer_request.status }}
                            </template>
                            <template v-else>
                                Content preview status:
                                {{ topic.content_writer_request.status }}
                            </template>
                        </template> -->
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-modal
            class="full-screen-modal"
            :id="'modalPopoverImageView'"
            v-model="showModal"
            hide-header
            hide-footer
        >
            <button class="closeModalPopoverImageView" @click="closeModalPopoverImageView">
                <feather-icon icon="XIcon" size="12" class="handle" />
            </button>
            <img :src="popoverImage.url" alt="" />
        </b-modal>
    </b-modal>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BFormTextarea,
    BImg,
    BTh,
    BTd,
    BTbody,
    BOverlay,
    BTfoot,
    BTab,
    BTabs,
    BSpinner,
    BFormSelect,
    BModal,
} from "bootstrap-vue";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";  
// import CKEditor from 'ckeditor4-vue';
import { VueEditor } from "vue2-editor";
import TopicBoardComment from "../../components/topic/TopicBoardComment.vue";
import TopicBoardAttachments from "../../components/topic/TopicBoardAttachments.vue";
import * as Helpers from '../../../Helpers';
import TokenService from '../../../TokenService';
import ContentWriterRequestModal from "./ContentWriterRequestModal.vue";

export default {
    components: {
        VueEditor,
        // 'ckeditor': CKEditor.component,/
        vSelect,
        BCard,
        ToastificationContent,
        BRow,
        BOverlay,
        BImg,
        BCol,
        BFormInput,
        BFormTextarea,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BTableSimple,
        BThead,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        BTab,
        BTabs,
        BSpinner,
        BFormSelect,
        TopicBoardComment,
        TopicBoardAttachments,
        BModal,
        ContentWriterRequestModal,
    },
    props: {
        props_showTopicBoardModal: {
            type: Boolean,
            required: true,
        },
        props_selectedTopic: {
            type: Object,
            required: true,
        },
        props_topicStatus: {
            type: Object,
            required: true,
        },
    },
    watch: {
        props_showTopicBoardModal() {
            this.showTopicBoardModal = this.props_showTopicBoardModal;
        },
        props_selectedTopic() {
            this.fetchTopicDetails(this.props_selectedTopic._id);
        },
        props_topicStatus() {
            this.topicStatus = this.props_topicStatus;
        },
    },
    data() {
        return {
            showTopicBoardModal: false,
            bearerToken: null,
            isLoading: false,
            topic: null,
            topicStatus: null,
            users: null,
            statuses: null,
            assigneeUser: "",
            reporterUser: "",
            writerUser: "",
            previousAssigneeUser: "",
            previousReporterUser: "",
            previousWriterUser: "",
            grade: "",
            topicHistory: [],
            topicAttachments: [],
            comment: 'hello',
            editorConfigs () {
                return {
                    // toolbar: [
                    //   ['Undo', 'Redo'], 
                    //   ['Source'], 
                    //   ['Styles', 'Format', 'Font', 'FontSize', 'TextColor', 'BGColor'],
                    //   ['PasteFromWord', 'Paste', 'CopyFormatting', 'RemoveFormat'],
                    //   ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'Specialchar'],
                    //   ['Link', 'Unlink', 'NumberedList', 'BulletedList', 'Indent', 'Outdent', 'blocks', 'align', 'bidi', 'paragraph']

                    // ],
                    allowedContent: true, // Disable the content filter completely
                    extraAllowedContent: 'div[id]; a[href] toc[id]',
                    removeButtons: 'Save,NewPage,Preview,ExportPdf,Print,Templates,Find,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,HiddenField,Button,ImageButton,CreateDiv,Language,Maximize,About,PageBreak,Anchor,Table,Smiley,PasteFromWord,Paste,PasteText'

                }
            },
            Helpers,
            TokenService,
            popoverImage: {},
            showModal: false,
        };
    },
    created() {
        this.bearerToken = "Bearer " + localStorage.getItem("_t");
    },
    methods: {
        popImage(data){
            this.popoverImage = data;
            this.showModal = true;
        },
        closeModalPopoverImageView(){
            this.showModal = false;
        },
        formatDate(dateObj) {
            const utcDate = new Date(dateObj);

            const gmtOffset = 5;
            const offsetInMilliseconds = gmtOffset * 60 * 60 * 1000;

            const gmtDate = new Date(utcDate.getTime() + offsetInMilliseconds);

            const day = String(gmtDate.getUTCDate()).padStart(2, '0');
            const month = String(gmtDate.getUTCMonth() + 1).padStart(2, '0');
            const year = gmtDate.getUTCFullYear();
            const hours = String(gmtDate.getUTCHours()).padStart(2, '0');
            const minutes = String(gmtDate.getUTCMinutes()).padStart(2, '0');
            const seconds = String(gmtDate.getUTCSeconds()).padStart(2, '0');

            return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} GMT+5`;

        },
        async changeTopicStatus() {
            try {
                let payload = {};
                payload.statusId = this.topicStatus;
                await axios.post(
                    process.env.VUE_APP_API_URL +
                    `/topic/board/${this.props_selectedTopic._id}`,
                    payload,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.fetchTopicDetails(this.props_selectedTopic._id);
            } catch (error) { }
        },
        async changeTopicReviewer() {
            console.log('Selected Writer:', this.assigneeUser, this.previousAssigneeUser);
            try {
                let payload = {};
                // payload.assigneeId = this.assigneeUser;
                payload.assignees = this.assignees;
                // payload.previousAssigneeId = this.previousAssigneeUser;
                await axios.post(
                    process.env.VUE_APP_API_URL +
                    `/topic/board/${this.props_selectedTopic._id}`,
                    payload,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.fetchTopicDetails(this.props_selectedTopic._id);
            } catch (error) { }
        },
        async changeTopicReporter() {
            try {
                let payload = {};
                payload.auditorId = this.reporterUser;
                payload.previousAuditorId = this.previousReporterUser;
                await axios.post(
                    process.env.VUE_APP_API_URL +
                    `/topic/board/${this.props_selectedTopic._id}`,
                    payload,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.fetchTopicDetails(this.props_selectedTopic._id);
            } catch (error) { }
        },
        async changeTopicEditor() {
            // console.log('Selected Writer:', this.writerUser, this.previousWriterUser);
            try {
                let payload = {};
                payload.editors = this.editors;
                await axios.post(
                    process.env.VUE_APP_API_URL +
                    `/topic/board/${this.props_selectedTopic._id}`,
                    payload,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.fetchTopicDetails(this.props_selectedTopic._id);
            } catch (error) { }
        },
        async fetchTopicDetails(topicId) {
            try {
                let res = await axios.get(
                    process.env.VUE_APP_API_URL + `/topic/board/${topicId}`,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.$emit("updatedParent", false);
                this.statuses = res.data.statuses;
                this.users = res.data.users;
                this.grade = res.data.grade;
                this.topicStatus = res.data.topic.statusId;
                // this.assigneeUser = res.data.topic.assigneeId;
                this.assignees = res.data.topic.assignees;
                this.reporterUser = res.data.topic.auditorId;
                this.writerUser = res.data.topic.writerId;
                this.editors = res.data.topic.editors;
                this.previousAssigneeUser = res.data.topic.assigneeId;
                this.previousReporterUser = res.data.topic.auditorId;
                this.previousWriterUser = res.data.topic.writerId;
                this.topicHistory = res.data.history;
                this.topicAttachments = res.data.topicAttachments;
                this.topic = res.data.topic;
                // console.log('res.data.topic=',res.data.topic)
            } catch (error) {
                this.isLoading = false;
                console.error("Error saving image to gallery", error);
            }
        },
        triggerFileInput() {
            this.$refs["gallery_image_upload"].click();
        },
        async uploadFile(file, url, key,Editor, cursorLocation, resetUploader) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error("Error uploading file:", response);
                }
                let s3Url =
                    "https://zape02.s3.ap-southeast-1.amazonaws.com/" + key;
                this.createGalleryImage(s3Url, file.name,Editor, cursorLocation, resetUploader);
            } catch (error) {
                this.isLoading = false;
                console.error("Error uploading file:", error);
            }
        },
        async createGalleryImage(s3Url, fileName,Editor, cursorLocation, resetUploader) {
            try {
                let galleryObj = {
                    name: fileName,
                    url: s3Url,
                };
                await axios.post(
                    process.env.VUE_APP_API_URL + "/gallery/image/create",
                    galleryObj,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                    
                Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
            } catch (error) {
                this.isLoading = false;
                console.error("Error saving image to gallery", error);
            }
        },
        async ImageRendererItems(event,Editor, cursorLocation, resetUploader) {
            const file = event.target.files[0];
            if (file) {
                this.isLoading = true;
                try {
                    const fileType = file.type;
                    const fileExtension = this.getFileExtension(fileType);
                    const response = await axios.post(
                        process.env.VUE_APP_API_URL +
                        "/s3/generate-presigned-url",
                        {
                            type: file.type,
                            ext: fileExtension,
                        },
                        {
                            headers: {
                                Authorization: this.bearerToken,
                            },
                        }
                    );
                    const { url, key } = response.data;
                    await this.uploadFile(file, url, key,Editor,cursorLocation,resetUploader);
                } catch (error) {
                    this.isLoading = false;
                    console.error("Error getting pre-signed URL:", error);
                }
            }
        },

        getFileExtension(mimeType) {
            const mimeToExtension = {
                "image/jpeg": "jpg",
                "image/png": "png",
                "image/gif": "gif",
            };
            return mimeToExtension[mimeType] || "png";
        },
        onModalHidden() {
            this.showTopicBoardModal = false;
            this.$emit("modelClose", false);
        },
        getTopicData(){
            this.fetchTopicDetails(this.props_selectedTopic._id)
        },
        contentWriterRequestModalUpdated(){
            this.getTopicData();
        },
    },
};
</script>
